// cSpell:disable
import { useState, useContext, useCallback, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { Layout, message } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";

import "./App.css";
import ConfigContext from "./ConfigContext";
import LogIn from "./pages/logIn/LogIn";
import Home from "./pages/home/Home";
import ErrorFallback from "./components/errorFallback/ErrorFallback";
import Spinner from "./components/spinner/Spinner";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Summary from "./components/summary/Summary";
// import Notice from './components/notice/Notice';
import { loginRequest } from "./auth/authConfig";
import { callMsGraph } from "./auth/graphConfig";

const App = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [waitingList, setWaitingList] = useState([]);
  const [totalList, setTotalList] = useState([]);
  const [summary, setSummary] = useState(null);
  const [crewStatus, setCrewStatus] = useState([]);
  const [songList, setSongList] = useState([]);
  const [specialCases, setSpecialCases] = useState([]);
  const [doubleReg, setDoubleReg] = useState([]);
  const [crewList, setCrewList] = useState([]);
  const [dailyLogList, setDailyLogList] = useState([]);
  const [logMainTypes, setLogMainTypes] = useState([]);
  const [logSubTypesByMember, setLogSubTypesOfMember] = useState([]);
  const [logSubTypesByCrew, setLogSubTypesOfCrew] = useState([]);
  const [preBookingOverview, setPreBookingOverview] = useState(null);
  const { apiUrl } = useContext(ConfigContext);
  axios.defaults.baseURL = apiUrl;

  // MSAL authentication
  const { instance, accounts, inProgress } = useMsal();
  // cSpell:enable

  const requestProfileData = useCallback(() => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }, [instance, accounts]);

  const getDailyLogList = async () => {
    console.log("Fetching daily log list...");
    try {
      const { data } = await axios.get("/te/daily-log/list");
      setDailyLogList(data);
    } catch (error) {
      console.log(error.message);
      setIsError(true);
    }
  };

  const fetchSpecialCases = useCallback(async () => {
    console.log("Fetching special cases...");
    try {
      const { data } = await axios.get("/te/special-cases");
      setSpecialCases(data);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  }, []);

  const fetchDoubleReg = useCallback(async () => {
    console.log("Fetching double registration...");
    try {
      const { data } = await axios.get("/te/double-registration-list");
      setDoubleReg(data);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  }, []);

  // Get user profile from microsoft
  useEffect(() => {
    if (accounts.length > 0) {
      requestProfileData();
    }
  }, [accounts, requestProfileData]);

  // Get current user info
  useEffect(() => {
    const getCurrentUser = async (mail) => {
      setIsLoading(true);
      try {
        console.log("Getting user info...");
        const { data } = await axios.get(`/te/supervisor/${mail}/information`);
        axios.defaults.headers.common["User-Code"] = data.code;
        setCurrentUser(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        if (error.message.includes("404")) {
          message.error(
            "Your account is not supervisor. Please logout and try again.",
            5
          );
          setTimeout(() => {
            instance.logout();
          }, 5000);
        } else {
          message.error(
            "Get user information failed. Please refresh and try again.",
            5
          );
        }
      }
    };
    if (graphData) {
      getCurrentUser(graphData.mail);
    }
  }, [graphData, instance]);

  // Get Pre-Booking overview every 5 minutes
  useEffect(() => {
    if (currentUser) {
      const fetchPreBookingOverview = async () => {
        console.log("Fetching pre-booking overview...");
        try {
          const { data } = await axios.get("/te/pre-booking-overview");
          setPreBookingOverview(data);
        } catch (error) {
          console.log(error);
          setIsError(true);
        }
      };
      fetchPreBookingOverview();
      const repeat = setInterval(() => {
        fetchPreBookingOverview();
      }, 1000 * 60 * 5);
      return () => clearInterval(repeat);
    }
  }, [currentUser]);

  // Get waiting list every 10 seconds
  useEffect(() => {
    if (currentUser) {
      const fetchWaitingList = async () => {
        console.log("Fetching waiting list...");
        try {
          const { data } = await axios.get("/te/waiting-list");
          setWaitingList(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      fetchWaitingList();
      const repeat = setInterval(() => {
        fetchWaitingList();
      }, 10000);
      return () => clearInterval(repeat);
    }
  }, [currentUser]);

  // Get total list every 10 seconds
  useEffect(() => {
    if (currentUser) {
      const fetchTotalList = async () => {
        console.log("Fetching total list...");
        try {
          const { data } = await axios.get("/te/total-list");
          setTotalList(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      fetchTotalList();
      const repeat = setInterval(() => {
        fetchTotalList();
      }, 10000);
      return () => clearInterval(repeat);
    }
  }, [currentUser]);

  // Get summary data every 10 seconds
  useEffect(() => {
    if (currentUser) {
      const fetchSummary = async () => {
        console.log("Fetching summary...");
        try {
          const { data } = await axios.get("/te/summary-information");
          setSummary(data);
        } catch (error) {
          console.log(error);
          setIsError(true);
        }
      };
      fetchSummary();
      const repeat = setInterval(() => {
        fetchSummary();
      }, 10000);
      return () => clearInterval(repeat);
    }
  }, [currentUser]);

  // Get crew status every 10 seconds
  useEffect(() => {
    if (currentUser) {
      const fetchCrewStatus = async () => {
        console.log("Fetching crew status...");
        try {
          const { data } = await axios.get("/te/crew-status-list");
          setCrewStatus(data);
        } catch (error) {
          console.log(error);
          setIsError(true);
        }
      };
      fetchCrewStatus();
      const repeat = setInterval(() => {
        fetchCrewStatus();
      }, 10000);
      return () => clearInterval(repeat);
    }
  }, [currentUser]);

  // Get double registrations every 10 seconds
  useEffect(() => {
    if (currentUser) {
      fetchDoubleReg();
      const repeat = setInterval(() => {
        fetchDoubleReg();
      }, 10000);
      return () => clearInterval(repeat);
    }
  }, [currentUser, fetchDoubleReg]);

  // Get special cases every 10 seconds
  useEffect(() => {
    if (currentUser) {
      // const fetchSpecialCases = async () => {
      //   console.log('Fetching special cases...');
      //   try {
      //     const { data } = await axios.get('/te/special-cases');
      //     setSpecialCases(data);
      //   } catch (error) {
      //     console.log(error);
      //     setIsError(true);
      //   }
      // };
      fetchSpecialCases();
      const repeat = setInterval(() => {
        fetchSpecialCases();
      }, 10000);
      return () => clearInterval(repeat);
    }
  }, [currentUser, fetchSpecialCases]);

  // Get song list
  useEffect(() => {
    if (currentUser) {
      const getSongList = async () => {
        console.log("Fetching song list...");
        try {
          const { data } = await axios.get("/te/song-list");
          setSongList(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      getSongList();
    }
  }, [currentUser]);

  // Get daily log list
  useEffect(() => {
    if (currentUser) {
      getDailyLogList();
    }
  }, [currentUser]);

  // Get all crewStatus
  useEffect(() => {
    if (currentUser) {
      const getCrewList = async () => {
        console.log("Fetching all crew list...");
        try {
          const { data } = await axios.get("/crew/list");
          setCrewList(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      getCrewList();
    }
  }, [currentUser]);

  // Get log main types
  useEffect(() => {
    if (currentUser) {
      const getLogMainTyoes = async () => {
        console.log("Fetching log main types...");
        try {
          const { data } = await axios.get("/te/daily-log/main-type");
          setLogMainTypes(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      getLogMainTyoes();
    }
  }, [currentUser]);

  // Get log sub types of member
  useEffect(() => {
    if (currentUser) {
      const getLogSubTypes = async () => {
        console.log("Fetching log sub types of member...");
        try {
          const { data } = await axios.get(
            "/te/daily-log/main-type/1/sub-type"
          );
          setLogSubTypesOfMember(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      getLogSubTypes();
    }
  }, [currentUser]);

  // Get log sub types of crew
  useEffect(() => {
    if (currentUser) {
      const getLogSubTypes = async () => {
        console.log("Fetching log sub types of member...");
        try {
          const { data } = await axios.get(
            "/te/daily-log/main-type/2/sub-type"
          );
          setLogSubTypesOfCrew(data);
        } catch (error) {
          console.log(error.message);
          setIsError(true);
        }
      };
      getLogSubTypes();
    }
  }, [currentUser]);

  if (accounts.length > 0) {
    if (isError) {
      return <ErrorFallback />;
    }
    return currentUser && !isLoading ? (
      <>
        <Layout>
          <Header
            currentUser={currentUser}
            instance={instance}
            setCurrentUser={setCurrentUser}
          />
          <div className="main-container">
            <Summary summary={summary} />
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Home
                    waitingList={waitingList}
                    totalList={totalList}
                    crewStatus={crewStatus}
                    songList={songList}
                    specialCases={specialCases}
                    doubleReg={doubleReg}
                    crewList={crewList}
                    dailyLogList={dailyLogList}
                    logMainTypes={logMainTypes}
                    logSubTypesByMember={logSubTypesByMember}
                    logSubTypesByCrew={logSubTypesByCrew}
                    getDailyLogList={getDailyLogList}
                    fetchSpecialCases={fetchSpecialCases}
                    fetchDoubleReg={fetchDoubleReg}
                    preBookingOverview={preBookingOverview}
                  />
                )}
              />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </Layout>
        {/* <Notice /> */}
      </>
    ) : (
      <Spinner text="Loading..." />
    );
  } else if (inProgress === "login") {
    return <Spinner text="Logging In..." />;
  } else {
    return <LogIn instance={instance} setIsError={setIsError} />;
  }
};

export default App;
