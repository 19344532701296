// cSpell:disable
import { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
// cSpell:enable

import "./Home.css";
import WaitingList from "../../components/waitingList/WaitingList";
import CrewStatus from "../../components/crewStatus/CrewStatus";
import SpecialCases from "../../components/specialCases/SpecialCases";
import TotalList from "../../components/total/TotalList";
import DoubleReg from "../../components/doubleReg/DoubleReg";
import CrewDetail from "../../components/crewStatus/CrewDetail";
import PreBookingOverview from "../../components/preBookingOverview/PreBookingOverview";

const { TabPane } = Tabs;

const Home = ({
  waitingList,
  totalList,
  crewStatus,
  songList,
  specialCases,
  doubleReg,
  crewList,
  dailyLogList,
  logMainTypes,
  logSubTypesByMember,
  logSubTypesByCrew,
  getDailyLogList,
  fetchSpecialCases,
  fetchDoubleReg,
  preBookingOverview,
}) => {
  const [showDailyLog, setShowDailyLog] = useState(false);
  const [showAddLogForm, setShowAddLogForm] = useState(false);
  const [showUpdateLogForm, setShowUpdateLogForm] = useState(false);
  const [selectLog, setSelectLog] = useState(null);
  const [mainType, setMainType] = useState("");
  const [selectMainType, setSelectMainType] = useState("");
  const [formAdd] = Form.useForm();
  const [formUpdate] = Form.useForm();

  const handleEditClick = (record) => {
    setSelectLog(record);
    setShowUpdateLogForm(true);
  };

  const columns = [
    {
      title: "Time Period",
      dataIndex: "timePeriod",
      render: (text) => {
        if (text === "1") return "早上";
        if (text === "2") return "下午";
        if (text === "3") return "晚上";
      },
      width: 108,
    },
    {
      title: "Main Type",
      dataIndex: "mainTypeName",
      width: 98,
    },
    {
      title: "Sub Type",
      dataIndex: "subTypeName",
    },
    {
      title: "Crew",
      dataIndex: "crewNo",
      render: (text) =>
        text !== "" && crewList.find((crew) => crew.crewNo === text).crewName,
    },
    {
      title: "Member",
      dataIndex: "memberNo",
      width: 86,
    },
    {
      title: "Description",
      dataIndex: "logContent",
    },
    {
      title: "Action",
      dataIndex: "seq",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => handleEditClick(record)}
        />
      ),
    },
  ];

  const handleAddFinish = async (values) => {
    const newLog = {
      ...values,
      memberNo: values.memberNo ? values.memberNo : "",
      crewNo: values.crewNo ? values.crewNo : "",
    };
    try {
      const { data } = await axios.post("/te/daily-log", newLog);
      if (data) {
        await getDailyLogList();
        formAdd.resetFields();
        setShowAddLogForm(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdateFinish = async (values) => {
    const updatedLog = {
      ...values,
      memberNo: values.memberNo ? values.memberNo : "",
      crewNo: values.crewNo ? values.crewNo : "",
    };
    try {
      const { data } = await axios.patch(
        `/te/daily-log/${selectLog.seq}`,
        updatedLog
      );
      if (data) {
        await getDailyLogList();
        formUpdate.resetFields();
        setShowUpdateLogForm(false);
        setSelectLog(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectLog) {
      setSelectMainType(selectLog.mainType);
    }
  }, [selectLog]);

  return (
    <div className="home-container">
      <Tabs defaultActiveKey="1" size="large" type="card">
        <TabPane tab="Waiting list" key="1">
          <div className="content-container">
            <WaitingList waitingList={waitingList} songList={songList} />
            <CrewStatus crewStatus={crewStatus} />
          </div>
        </TabPane>
        <TabPane tab="Total calls" key="2">
          <TotalList totalList={totalList} songList={songList} />
        </TabPane>
        <TabPane tab="Crew" key="3">
          <CrewDetail crewStatus={crewStatus} />
        </TabPane>
        <TabPane
          tab={
            <span className={specialCases.length > 0 ? "alert" : ""}>
              Special cases
            </span>
          }
          key="4"
        >
          <div className="content-container">
            <SpecialCases
              specialCases={specialCases}
              songList={songList}
              fetchSpecialCases={fetchSpecialCases}
              fetchDoubleReg={fetchDoubleReg}
            />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span className={doubleReg.length > 0 ? "alert" : ""}>
              Double Registrations
            </span>
          }
          key="5"
        >
          <DoubleReg
            doubleReg={doubleReg}
            songList={songList}
            fetchDoubleReg={fetchDoubleReg}
            fetchSpecialCases={fetchSpecialCases}
          />
        </TabPane>
        <TabPane tab="Pre-Booking Overview" key="6">
          <PreBookingOverview preBookingOverview={preBookingOverview} />
        </TabPane>
      </Tabs>
      <Button
        type="primary"
        size="large"
        className="daily-log-trigger-btn"
        onClick={() => setShowDailyLog(true)}
      >
        Daily Log
      </Button>
      <Drawer
        title="Daily Log"
        width={820}
        onClose={() => setShowDailyLog(false)}
        visible={showDailyLog}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Button onClick={() => setShowAddLogForm(true)} type="primary">
            Add New Log
          </Button>
        }
      >
        <Table
          rowKey="seq"
          dataSource={dailyLogList}
          columns={columns}
          pagination={false}
        />
        <Drawer
          title="Add New Log"
          width={600}
          closable={false}
          onClose={() => {
            formAdd.resetFields();
            setShowAddLogForm(false);
          }}
          visible={showAddLogForm}
        >
          <Form
            name="add-new-log-form"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            onFinish={handleAddFinish}
            form={formAdd}
          >
            <Form.Item
              name="timePeriod"
              label="Time Period"
              rules={[{ required: true }]}
            >
              <Radio.Group
                options={[
                  { label: "Morning", value: "1" },
                  { label: "Afternoon", value: "2" },
                  { label: "Night", value: "3" },
                ]}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item
              name="mainType"
              label="Main Type"
              rules={[{ required: true }]}
            >
              <Radio.Group
                options={logMainTypes.map((type) => ({
                  label: type.mainTypeName,
                  value: type.mainType,
                }))}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => setMainType(e.target.value)}
              />
            </Form.Item>
            {mainType !== "" &&
              (mainType === "2" ? (
                <>
                  <Form.Item
                    name="subType"
                    label="Sub Type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {logSubTypesByCrew.map((type) => (
                        <Select.Option key={type.subType} value={type.subType}>
                          {type.subTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="crewNo"
                    label="Crew"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {crewList.map((crew) => (
                        <Select.Option key={crew.crewNo} value={crew.crewNo}>
                          {crew.crewName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="subType"
                    label="Sub Type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {logSubTypesByMember.map((type) => (
                        <Select.Option key={type.subType} value={type.subType}>
                          {type.subTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="memberNo"
                    label="Member"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </>
              ))}
            <Form.Item
              name="logContent"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
              <Space>
                <Button
                  onClick={() => {
                    formAdd.resetFields();
                    setShowAddLogForm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
        <Drawer
          title="Update Log"
          width={600}
          closable={false}
          onClose={() => {
            formUpdate.resetFields();
            setShowUpdateLogForm(false);
            setSelectLog(null);
          }}
          visible={showUpdateLogForm}
        >
          {selectLog && (
            <Form
              name="update-log-form"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              onFinish={handleUpdateFinish}
              initialValues={{
                timePeriod: selectLog.timePeriod,
                mainType: selectLog.mainType,
                subType: selectLog.subType,
                crewNo: selectLog.crewNo,
                memberNo: selectLog.memberNo,
                logContent: selectLog.logContent,
              }}
            >
              <Form.Item
                name="timePeriod"
                label="Time Period"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  options={[
                    { label: "Morning", value: "1" },
                    { label: "Afternoon", value: "2" },
                    { label: "Night", value: "3" },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item
                name="mainType"
                label="Main Type"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  options={logMainTypes.map((type) => ({
                    label: type.mainTypeName,
                    value: type.mainType,
                  }))}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => setSelectMainType(e.target.value)}
                />
              </Form.Item>
              {selectMainType === "2" ? (
                <>
                  <Form.Item
                    name="subType"
                    label="Sub Type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {logSubTypesByCrew.map((type) => (
                        <Select.Option key={type.subType} value={type.subType}>
                          {type.subTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="crewNo"
                    label="Crew"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {crewList.map((crew) => (
                        <Select.Option key={crew.crewNo} value={crew.crewNo}>
                          {crew.crewName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="subType"
                    label="Sub Type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {logSubTypesByMember.map((type) => (
                        <Select.Option key={type.subType} value={type.subType}>
                          {type.subTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="memberNo"
                    label="Member"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="logContent"
                label="Description"
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                <Space>
                  <Button
                    onClick={() => {
                      formUpdate.resetFields();
                      setShowUpdateLogForm(false);
                      setSelectLog(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}
        </Drawer>
      </Drawer>
    </div>
  );
};

export default Home;
