/* cSpell:disable*/
import { useState, useEffect, useRef } from 'react';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

import './WaitingList.css';
import Assign from '../assign/Assign';
import Spinner from '../spinner/Spinner';
/* cSpell:enable*/

const filters = [
  { text: 'Song', value: 'Song' },
  { text: 'Book', value: 'Book' },
  { text: 'Right Light', value: 'Right Light' },
  { text: 'Reading', value: 'Reading' },
  { text: 'Free Conversation', value: 'Free' },
  { text: 'CAP', value: 'CAP' },
];

const WaitingList = ({ waitingList, songList }) => {
  const [showAssign, setShowAssign] = useState(false);
  const [selectReg, setSelectReg] = useState(null);
  const [reserveTimeFilters, setReserveTimeFilters] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectRegTime, setSelectRegTime] = useState(null);
  const [selectRegAllChildren, setSelectRegAllChildren] = useState([]);

  const fetchRegList = async (memNo, regTime) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/${memNo}/${regTime}/reg-list`);
      if (data) {
        setSelectReg(data);
        setIsLoading(false);
        setShowAssign(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchAllChildren = async (memNo) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/${memNo}/child-list`);
      if (data.length > 0) {
        setSelectRegAllChildren(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const handleAssignClick = (record) => {
    setSelectRegTime(record.regTime);
    fetchRegList(record.memberNo, record.regTime);
    fetchAllChildren(record.memberNo);
  };

  let searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search member number"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            searchInput && searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '',
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => handleAssignClick(record)}
        >
          Assign
        </Button>
      ),
      width: 80,
    },
    {
      title: 'Member',
      dataIndex: 'memberNo',
      render: (text, record) => {
        const isNew = record.isNewMember;
        if (isNew) {
          return (
            <>
              {text}
              <span style={{ color: 'red' }}>(NEW)</span>
            </>
          );
        }
        return text;
      },
      width: 90,
      ...getColumnSearchProps('memberNo'),
    },
    {
      title: 'Kid',
      dataIndex: 'regKids',
      render: (text, record) => `${text} / ${record.familyKids}`,
      sorter: (a, b) => a.regKids - b.regKids,
      width: 80,
    },
    {
      title: 'Crew',
      dataIndex: 'crewName',
      width: 100,
    },
    {
      title: 'Register',
      dataIndex: 'receiveTime',
      width: 90,
    },
    {
      title: 'Reserve',
      dataIndex: 'reserveTime',
      filters: reserveTimeFilters,
      onFilter: (value, record) => record.reserveTime.indexOf(value) === 0,
      sorter: (a, b) =>
        parseInt(
          `${a.reserveTime.split(':')[0]}${a.reserveTime.split(':')[1]}`
        ) -
        parseInt(
          `${b.reserveTime.split(':')[0]}${b.reserveTime.split(':')[1]}`
        ),
      width: 120,
    },
    {
      title: 'Realtime',
      dataIndex: 'realTimeReg',
      sorter: (a, b) =>
        parseInt(
          `${a.realTimeReg.split(':')[0]}${a.realTimeReg.split(':')[1]}`
        ) -
        parseInt(
          `${b.realTimeReg.split(':')[0]}${b.realTimeReg.split(':')[1]}`
        ),
      width: 110,
    },
    {
      title: 'Callback',
      dataIndex: 'callback',
      width: 90,
    },
    {
      title: 'Type',
      render: (text, record) =>
        record.lessons.map((lesson, i) => (
          <p key={i}>{lesson.replace('Lessons', '')}</p>
        )),
      width: 144,
      filters,
      onFilter: (value, record) =>
        record.lessons.find((lesson) => lesson.indexOf(value) === 0),
    },
    {
      title: 'Repass',
      dataIndex: 'repass',
      render: (text) => (text === 0 ? '' : text),
      width: 76,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  useEffect(() => {
    if (waitingList.length > 0) {
      const allReserveTime = waitingList.map((item) => item.reserveTime);
      const newFilters = [...new Set(allReserveTime)].map((item) => ({
        text: item,
        value: item,
      }));
      setReserveTimeFilters(newFilters);
    }
  }, [waitingList]);

  return (
    <>
      {isLoading && <Spinner text="Loading..." />}
      <div className="waiting-list-container">
        <Table
          rowKey="seq"
          columns={columns}
          dataSource={waitingList}
          pagination={false}
          scroll={{ y: 546 }}
        />
      </div>
      {selectReg && (
        <Assign
          showDrawer={showAssign}
          setShowDrawer={setShowAssign}
          selectReg={selectReg}
          setSelectReg={setSelectReg}
          selectRegTime={selectRegTime}
          setSelectRegTime={setSelectRegTime}
          fetchRegList={fetchRegList}
          songList={songList}
          selectRegAllChildren={selectRegAllChildren}
        />
      )}
    </>
  );
};

export default WaitingList;
