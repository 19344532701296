import './Summary.css';
import SummaryItem from './SummaryItem';

const Summary = ({ summary }) => {
  return (
    <div className="summary-wrapper">
      <div className="summary-container">
        {summary &&
          summary.teSummaryBar.map((item) => {
            if (
              item.title === '預約時段超時' ||
              item.title === '即時報名超時' ||
              item.title === '預約回撥超時' ||
              item.title === '重複報名筆數' ||
              item.title === '重複報名警示'
            ) {
              return false;
            }
            return (
              <SummaryItem
                key={item.title}
                title={item.title}
                value={item.value}
                assignedNotCallCrew={summary.assignedNotCallCrew}
                reserveNotAssign={
                  summary.teSummaryBar.find(
                    (item) => item.title === '預約時段超時'
                  ).value
                }
                realTimeNotAssign={
                  summary.teSummaryBar.find(
                    (item) => item.title === '即時報名超時'
                  ).value
                }
                reverseNotCall={
                  summary.teSummaryBar.find(
                    (item) => item.title === '預約回撥超時'
                  ).value
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default Summary;
