import { useState, useEffect } from 'react';
import axios from 'axios';

import App from './App';
import ConfigContext from './ConfigContext';

const AppLoader = () => {
  const [config, setConfig] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!config) {
        await axios
          .get(
            `${window.location.protocol}//${window.location.host}/config.json`
          )
          .then((response) => {
            setConfig(response.data);
            setIsConfigLoaded(true);
          })
          .catch((error) => {
            console.log('Could not load application configuration.');
            console.log(error);
          });
      }
    })();
  }, [config]);

  return (
    isConfigLoaded && (
      <ConfigContext.Provider value={config}>
        <App />
      </ConfigContext.Provider>
    )
  );
};

export default AppLoader;
