import './ErrorFallback.css';

const ErrorFallback = () => {
  return (
    <div className="container">
      <div class="error-502__container">
        <div class="error-502">
          <div class="error-502__text">
            <h1>
              Unexpected Error <b>:(</b>
            </h1>
            <h2>
              <p>You can refresh this page and try again.</p>
            </h2>
          </div>
        </div>
      </div>
      <div class="gears">
        <div class="gear one">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
        <div class="gear two">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
        <div class="gear three">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
