// cSpell:disable
import { Layout } from 'antd';
// cSpell:enable

import './Footer.css';

const Footer = ({ bgColor }) => {
  return (
    <Layout.Footer className="footer" style={{ backgroundColor: bgColor }}>
      Copyright World Family English, Ltd. &copy; 2022. All Rights Reserved.
    </Layout.Footer>
  );
};

export default Footer;
