/* cSpell:disable*/
import { useState } from 'react';
import { Button, Table } from 'antd';
import axios from 'axios';

import './DoubleReg.css';
import Assign from '../assign/Assign';
import Spinner from '../spinner/Spinner';
/* cSpell:enable*/

const DoubleReg = ({
  doubleReg,
  songList,
  fetchDoubleReg,
  fetchSpecialCases,
}) => {
  const [showAssign, setShowAssign] = useState(false);
  const [selectReg, setSelectReg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectRegTime, setSelectRegTime] = useState(null);
  const [selectRegAllChildren, setSelectRegAllChildren] = useState([]);

  const fetchRegList = async (memNo, regTime) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/${memNo}/${regTime}/reg-list`);
      if (data) {
        setSelectReg(data);
        setIsLoading(false);
        setShowAssign(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchAllChildren = async (memNo) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/${memNo}/child-list`);
      if (data.length > 0) {
        setSelectRegAllChildren(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const handleAssignClick = (record) => {
    setSelectRegTime(record.regTime);
    fetchRegList(record.memberNo, record.regTime);
    fetchAllChildren(record.memberNo);
  };

  const columns = [
    {
      title: '',
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => handleAssignClick(record)}
        >
          Assign
        </Button>
      ),
      width: 80,
    },
    {
      title: 'Member',
      dataIndex: 'memberNo',
      render: (text, record) => {
        const isNew = record.isNewMember;
        const newLabel = isNew ? '(NEW)' : null;
        if (isNew) {
          return (
            <p>
              {text} <span style={{ color: 'red' }}>{newLabel}</span>
            </p>
          );
        }
        return text;
      },
      width: 90,
    },
    {
      title: 'Kid',
      dataIndex: 'regKids',
      render: (text, record) => `${text} / ${record.familyKids}`,
      width: 80,
    },
    {
      title: 'Register',
      dataIndex: 'receiveTime',
      width: 90,
    },
    {
      title: 'Reserve',
      dataIndex: 'reserveTime',
      width: 90,
    },
    {
      title: 'Realtime',
      dataIndex: 'realTimeReg',
      width: 90,
    },
    {
      title: 'Type',
      render: (text, record) =>
        record.lessons.map((lesson, i) => (
          <p key={i}>{lesson.replace('Lessons', '')}</p>
        )),
      width: 144,
    },
    {
      title: 'Detail',
      render: (text, record) =>
        record.regDetail.map((child, i) => (
          <p key={i}>{`${child.childChineseName} - ${child.songName1}${
            child.songName2 !== '' ? ' 、 ' : ''
          }${child.songName2}`}</p>
        )),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  return (
    <>
      {isLoading && <Spinner text="Loading..." />}
      <div className="double-reg-container">
        <Table
          rowKey="seq"
          dataSource={doubleReg}
          columns={columns}
          pagination={false}
          scroll={{ y: 546 }}
        />
      </div>
      {selectReg && (
        <Assign
          showDrawer={showAssign}
          setShowDrawer={setShowAssign}
          selectReg={selectReg}
          setSelectReg={setSelectReg}
          selectRegTime={selectRegTime}
          setSelectRegTime={setSelectRegTime}
          fetchRegList={fetchRegList}
          songList={songList}
          selectRegAllChildren={selectRegAllChildren}
          fetchSpecialCases={fetchSpecialCases}
          fetchDoubleReg={fetchDoubleReg}
        />
      )}
    </>
  );
};

export default DoubleReg;
