/* cSpell:disable*/
import { Table } from 'antd';

import './CrewStatus.css';
/* cSpell:enable*/

const CrewStatus = ({ crewStatus }) => {
  const columns = [
    {
      title: 'Crew',
      dataIndex: 'crewName',
      width: 100,
      render: (text, record) => (
        <>
          <p>{text}</p>
          <p>{record.crewNo}</p>
        </>
      ),
    },
    {
      title: 'Idle\n(mins)',
      dataIndex: 'unavailableTime',
      width: 75,
      render: (text) => text !== 0 && text,
    },
    {
      title: 'Target',
      dataIndex: 'memberNo',
    },
    {
      title: 'Calling\n(mins)',
      dataIndex: 'callingTime',
      width: 88,
      render: (text) => text !== 0 && text,
    },
  ];

  const renderWarning = (record) => {
    if (
      record.unavailableTime >= 10 ||
      (record.memberNo !== '' && record.callingTime >= record.kidsCount * 10)
    ) {
      return 'warning';
    }
  };

  return (
    <div className="crew-status-container">
      <Table
        rowKey="crewNo"
        columns={columns}
        dataSource={crewStatus}
        pagination={false}
        scroll={{ y: 526 }}
        rowClassName={(record) => renderWarning(record)}
        className="crew-status-table"
      />
    </div>
  );
};

export default CrewStatus;
