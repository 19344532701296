/* cSpell:disable*/
import { useState, useRef } from 'react';
import { Button, Input, message, Popconfirm, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';

import './CrewStatus.css';
import Spinner from '../spinner/Spinner';
/* cSpell:enable*/

const CrewDetail = ({ crewStatus }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search member number"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            searchInput && searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleClearClick = async (record) => {
    setIsLoading(true);
    try {
      const { data } = await axios.patch('/te/clear', {
        crewNo: record.crewNo,
        memberNo: record.memberNo,
      });
      if (data) {
        message.success('清除成功', 5);
        setIsLoading(false);
      } else {
        message.error('清除失敗，請聯絡管理員', 5);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Clear Assign',
      dataIndex: 'showClearButton',
      render: (text, record) =>
        text && (
          // cSpell:disable
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => handleClearClick(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" size="small">
              Clear
            </Button>
          </Popconfirm>
          // cSpell:enable
        ),
      width: 110,
    },
    {
      title: 'Crew',
      dataIndex: 'crewName',
      ...getColumnSearchProps('crewName'),
    },
    {
      title: 'Crew No',
      dataIndex: 'crewNo',
      ...getColumnSearchProps('crewNo'),
    },
    {
      title: 'Ext No',
      dataIndex: 'extNo',
    },
    {
      title: 'Idle(mins)',
      dataIndex: 'unavailableTime',
      render: (text) => text !== 0 && text,
    },
    {
      title: 'Target',
      dataIndex: 'memberNo',
      ...getColumnSearchProps('memberNo'),
      width: 110,
    },
    {
      title: 'Kids',
      dataIndex: 'kidsCount',
      render: (text) => text !== 0 && text,
      width: 140,
    },
    {
      title: 'Type',
      render: (text, record) =>
        record.lessonCategories.map((lesson, i) => (
          <p key={i}>{lesson.replace('Lessons', '')}</p>
        )),
      width: 144,
    },
    {
      title: 'Calling(mins)',
      dataIndex: 'callingTime',
      render: (text) => text !== 0 && text,
    },
    {
      title: 'Login',
      dataIndex: 'logInTime',
    },
    {
      title: 'Logout',
      dataIndex: 'logOutTime',
    },
    {
      title: 'Finished',
      dataIndex: 'finishedCount',
    },
    {
      title: 'Repass',
      dataIndex: 'repassCount',
    },
  ];

  const renderWarning = (record) => {
    if (
      record.unavailableTime >= 10 ||
      (record.memberNo !== '' && record.callingTime >= record.kidsCount * 10)
    ) {
      return 'warning';
    }
  };

  return (
    <>
      {isLoading && <Spinner text="Loading..." />}
      <div className="crew-detail-container">
        <Table
          rowKey="crewNo"
          columns={columns}
          dataSource={crewStatus}
          pagination={false}
          scroll={{ y: 526 }}
          rowClassName={(record) => renderWarning(record)}
          className="crew-status-table"
        />
      </div>
    </>
  );
};

export default CrewDetail;
