import { Descriptions, List } from "antd";
import React from "react";

const PreBookingOverview = ({ preBookingOverview }) => {
  return (
    <div style={{ padding: "20px 20px 0" }}>
      {new Date().getDay() === 3 && (
        <div style={{ marginBottom: 40 }}>
          <Descriptions title="Afternoon" />
          <List
            itemLayout="horizontal"
            dataSource={[
              "Time Slot",
              "14:00 ~ 14:30",
              "14:30 ~ 15:00",
              "15:00 ~ 15:30",
              "15:30 ~ 16:00",
              "16:00 ~ 16:30",
              "16:30 ~ 17:00",
              "17:00 ~ 17:30",
              "17:30 ~ 18:00",
            ]}
            renderItem={(item) => (
              <span
                style={{
                  margin: "5px 20px",
                  fontWeight: "bold",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item}
              </span>
            )}
          />
          <List
            itemLayout="horizontal"
            dataSource={["Seats Available", ...preBookingOverview?.afternoon]}
            renderItem={(item, i) =>
              i === 0 ? (
                <span
                  style={{
                    margin: "5px 20px",
                    fontWeight: "bold",
                    width: 100,
                    display: "inline-block",
                  }}
                >
                  {item}
                </span>
              ) : (
                <span
                  style={{
                    margin: "5px 20px",
                    width: 100,
                    display: "inline-block",
                  }}
                >
                  {item.seatsAvailable}
                </span>
              )
            }
          />
          <List
            itemLayout="horizontal"
            dataSource={["Pre Booked", ...preBookingOverview?.afternoon]}
            renderItem={(item, i) =>
              i === 0 ? (
                <span
                  style={{
                    margin: "5px 20px",
                    fontWeight: "bold",
                    width: 100,
                    display: "inline-block",
                  }}
                >
                  {item}
                </span>
              ) : (
                <span
                  style={{
                    margin: "5px 20px",
                    width: 100,
                    display: "inline-block",
                  }}
                >
                  {item.preBooked}
                </span>
              )
            }
          />
          <List
            itemLayout="horizontal"
            dataSource={["Percentage", ...preBookingOverview?.afternoon]}
            renderItem={(item, i) =>
              i === 0 ? (
                <span
                  style={{
                    margin: "5px 20px",
                    fontWeight: "bold",
                    width: 100,
                    display: "inline-block",
                  }}
                >
                  {item}
                </span>
              ) : (
                <span
                  style={{
                    margin: "5px 20px",
                    width: 100,
                    display: "inline-block",
                  }}
                >
                  {item.percentage}
                </span>
              )
            }
          />
        </div>
      )}
      <div>
        <Descriptions title="Evening" />
        <List
          itemLayout="horizontal"
          dataSource={[
            "Time Slot",
            "18:00 ~ 18:30",
            "18:30 ~ 19:00",
            "19:00 ~ 19:30",
            "19:30 ~ 20:00",
            "20:00 ~ 20:30",
            "20:30 ~ 21:00",
          ]}
          renderItem={(item) => (
            <span
              style={{
                margin: "5px 20px",
                fontWeight: "bold",
                width: 100,
                display: "inline-block",
              }}
            >
              {item}
            </span>
          )}
        />
        <List
          itemLayout="horizontal"
          dataSource={["Seats Available", ...preBookingOverview?.evening]}
          renderItem={(item, i) =>
            i === 0 ? (
              <span
                style={{
                  margin: "5px 20px",
                  fontWeight: "bold",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item}
              </span>
            ) : (
              <span
                style={{
                  margin: "5px 20px",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item.seatsAvailable}
              </span>
            )
          }
        />
        <List
          itemLayout="horizontal"
          dataSource={["Pre Booked", ...preBookingOverview?.evening]}
          renderItem={(item, i) =>
            i === 0 ? (
              <span
                style={{
                  margin: "5px 20px",
                  fontWeight: "bold",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item}
              </span>
            ) : (
              <span
                style={{
                  margin: "5px 20px",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item.preBooked}
              </span>
            )
          }
        />
        <List
          itemLayout="horizontal"
          dataSource={["Percentage", ...preBookingOverview?.evening]}
          renderItem={(item, i) =>
            i === 0 ? (
              <span
                style={{
                  margin: "5px 20px",
                  fontWeight: "bold",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item}
              </span>
            ) : (
              <span
                style={{
                  margin: "5px 20px",
                  width: 100,
                  display: "inline-block",
                }}
              >
                {item.percentage}
              </span>
            )
          }
        />
      </div>
    </div>
  );
};

export default PreBookingOverview;
