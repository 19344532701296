// cSpell:disable
import { Spin } from 'antd';
// cSpell:enable

import './Spinner.css';

const Spinner = ({ text }) => {
  return (
    <div className="overlay">
      <Spin size="large" className="spinner" tip={text} />
    </div>
  );
};

export default Spinner;
