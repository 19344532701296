/*cSpell:disable*/
import { useState, useEffect } from 'react';
import {
  Button,
  Drawer,
  Space,
  Form,
  Row,
  Col,
  Table,
  Select,
  Checkbox,
  TimePicker,
  Typography,
  Popconfirm,
  message,
  Input,
} from 'antd';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';

import MemberChild from '../memberChild/MemberChild';
import Spinner from '../spinner/Spinner';
/*cSpell:enable*/

const Assign = ({
  showDrawer,
  setShowDrawer,
  selectReg,
  setSelectReg,
  selectRegTime,
  setSelectRegTime,
  fetchRegList,
  songList,
  selectRegAllChildren,
  fetchSpecialCases,
  fetchDoubleReg,
}) => {
  const [assignRightNow, setAssignRightNow] = useState(false);
  const [showNewChildDrawer, setShowNewChildDrawer] = useState(false);
  const [showUpdateChildDrawer, setShowUpdateChildDrawer] = useState(false);
  const [newPhone, setNewPhone] = useState(null);
  const [crewList, setCrewList] = useState([]);
  const [phoneList, setPhoneList] = useState([]);
  const [selectChild, setSelectChild] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [callingHistory, setCallingHistory] = useState([]);
  const [selectChildren, setSelectChildren] = useState([]);
  const [form] = Form.useForm();

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectChildren(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      childSeq: record.childSeq,
    }),
  };

  const getDisabledHours = () => {
    var hours = [];
    for (let i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };
  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const handleUpdateClick = (childSeq) => {
    setSelectChild(
      selectReg.regDetail.find((child) => child.childSeq === childSeq)
    );
    setShowUpdateChildDrawer(true);
  };

  const handleDeleteOneClick = async (seq) => {
    setIsLoading(true);
    try {
      const { data } = await axios.delete(`/te/child/${seq}`);
      if (data) {
        message.success('刪除成功', 5);
        fetchRegList(selectReg.memberNo, selectRegTime);
        setIsLoading(false);
      } else {
        message.error('刪除失敗, 請再試一次', 5);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleDeleteAllClick = async () => {
    setIsLoading(true);
    const reqBody = {
      memberNo: selectReg.memberNo,
      regTime: selectRegTime,
    };
    try {
      const { data } = await axios.delete('/te/registration', {
        data: reqBody,
      });
      if (data) {
        message.success('刪除成功', 5);
        setIsLoading(false);
        setSelectReg(null);
        setShowDrawer(false);
      } else {
        message.error('刪除失敗, 請再試一次', 5);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Kid',
      dataIndex: 'childChineseName',
      render: (text, record) => (
        <>
          {text}
          <br />
          {record.childEnglishName}
        </>
      ),
      width: 120,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 100,
    },
    {
      title: 'Lesson 1',
      dataIndex: 'songName1',
    },
    {
      title: 'Lesson 2',
      dataIndex: 'songName2',
    },
    {
      title: 'Update',
      dataIndex: 'childSeq',
      render: (text) => (
        <Button
          type="primary"
          size="small"
          icon={<EditOutlined />}
          onClick={() => handleUpdateClick(text)}
        />
      ),
      width: 60,
    },
    {
      title: 'Delete',
      dataIndex: 'seq',
      render: (text) => (
        // cSpell:disable
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => handleDeleteOneClick(text)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            size="small"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
        // cSpell:enable
      ),
      width: 60,
    },
  ];

  const repassHistoryColumns = [
    {
      title: 'Start',
      dataIndex: 'startTime',
      width: 90,
    },
    {
      title: 'End',
      dataIndex: 'endTime',
      width: 90,
    },
    {
      title: 'Crew No',
      dataIndex: 'assignCrewNo',
      width: 90,
    },
    {
      title: 'Crew Name',
      dataIndex: 'crewName',
      width: 150,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const handleDrawerClose = () => {
    form.resetFields();
    setShowDrawer(false);
    setSelectReg(null);
    setSelectRegTime(null);
  };

  const handleFinish = async (values) => {
    setIsLoading(true);
    const assignInfo = {
      crewNo: values.crewNo,
      phoneNumber:
        values.phoneNumber === 'other'
          ? values.customPhone
          : !values.phoneNumber
          ? selectReg.phoneNumber
          : values.phoneNumber,
      rightNowFlag: values.rightNowFlag,
      callback:
        values.callback === '' ? '' : moment(values.callback).format('HH:mm'),
      regTime: selectRegTime,
      memberNo: selectReg.memberNo,
    };
    try {
      const { data } = await axios.patch('/te/repass', assignInfo);
      if (data.isSuccess) {
        fetchSpecialCases();
        fetchDoubleReg();
        message.success('Repass 成功', 5);
        form.resetFields();
        setSelectReg(null);
        setSelectRegTime(null);
        setIsLoading(false);
        setShowDrawer(false);
      } else {
        message.error(data.message, 5);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  /*cSpell:disable*/
  const handleReRegister = async (values) => {
    setIsLoading(true);
    const assignInfo = {
      crewNo: values.crewNo,
      phoneNumber:
        values.phoneNumber === 'other'
          ? values.customPhone
          : !values.phoneNumber
          ? selectReg.phoneNumber
          : values.phoneNumber,
      rightNowFlag: values.rightNowFlag ? values.rightNowFlag : false,
      callback:
        values.callback === '' ? '' : moment(values.callback).format('HH:mm'),
      regTime: selectRegTime,
      memberNo: selectReg.memberNo,
      childSeqs: selectChildren,
    };
    console.log(assignInfo);
    try {
      const { data } = await axios.patch('/te/recall', assignInfo);
      if (data) {
        message.success('重新指派成功', 5);
        form.resetFields();
        setSelectReg(null);
        setSelectRegTime(null);
        setSelectChild([]);
        setIsLoading(false);
        setShowDrawer(false);
      } else {
        message.error('重新指派成功, 請重試', 5);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };
  /*cSpell:enable*/

  useEffect(() => {
    const getCrewList = async () => {
      try {
        const { data } = await axios.get(
          `/te/${selectReg.memberNo}/${selectRegTime}/crew-list`
        );
        setCrewList(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (selectReg) {
      getCrewList();
    }
  }, [selectReg, selectRegTime]);

  useEffect(() => {
    const getPhoneList = async () => {
      try {
        const { data } = await axios.get(
          `/te/${selectReg.memberNo}/phone-list`
        );
        setPhoneList(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (selectReg) {
      getPhoneList();
    }
  }, [selectReg]);

  useEffect(() => {
    const getCallingHistory = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `/te/${selectReg.memberNo}/call-records`
        );
        setCallingHistory(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        setIsLoading(false);
      }
    };
    if (selectReg && selectReg.callRecordFlag) {
      getCallingHistory();
    }
  }, [selectReg]);

  return (
    <>
      {isLoading && <Spinner text="Loading..." />}
      <Drawer
        title={
          selectReg &&
          (selectReg.memberNo.startsWith('D2')
            ? '2019 - Anna、Tommy'
            : '2018 - Ting Ting、Wei Wei')
        }
        visible={showDrawer}
        onClose={handleDrawerClose}
        width={1000}
      >
        {selectReg && (
          <>
            <Typography.Title level={5}>
              {selectReg.memberNo} - {selectReg.memberName}
            </Typography.Title>
            {selectReg.comment !== '' && (
              <Typography.Title level={5}>{selectReg.comment}</Typography.Title>
            )}
            <Button type="primary" onClick={() => setShowNewChildDrawer(true)}>
              Add kid
            </Button>
            <Table
              rowKey="childSeq"
              columns={columns}
              dataSource={selectReg.regDetail}
              pagination={false}
              style={{ margin: '10px 0 30px' }}
              rowSelection={
                selectReg.status === '1' && {
                  type: 'checkbox',
                  ...rowSelection,
                }
              }
            />
            <Form
              name="assign-form"
              onFinish={
                selectReg.status === '1' ? handleReRegister : handleFinish
              }
              form={form}
              initialValues={{
                crewNo: '',
                phoneNumber: selectReg.phoneNumber,
                callback: '',
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="crewNo" label="Crew">
                    <Select allowClear>
                      {crewList.map((crew) => (
                        <Select.Option value={crew.crewNo} key={crew.crewNo}>
                          {crew.crewNo} - {crew.crewName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label="Tel"
                    style={{ marginBottom: '8px' }}
                  >
                    <Select onChange={(val) => setNewPhone(val)}>
                      {phoneList.map((phone) => (
                        <Select.Option
                          value={phone.phoneNumber}
                          key={phone.phoneNumber}
                        >
                          {phone.phoneDesc}
                        </Select.Option>
                      ))}
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                  <span
                    style={{ marginTop: '-36px' }}
                  >{`目前顯示為${phoneList[0]?.memberNumber}的電話，指派前請確認是否與此筆報名${selectReg.memberNo}相同`}</span>
                </Col>
              </Row>
              {newPhone === 'other' && (
                <Row gutter={16}>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Form.Item name="customPhone" label="Tel no.">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item name="rightNowFlag" valuePropName="checked">
                    <Checkbox
                      onChange={(e) => setAssignRightNow(e.target.checked)}
                    >
                      Immediately
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item name="callback" label="Callback">
                    <TimePicker
                      disabled={assignRightNow}
                      format="HH:mm"
                      minuteStep={5}
                      disabledHours={getDisabledHours}
                      disabledMinutes={getDisabledMinutes}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Space>
                    <Button
                      htmlType="button"
                      onClick={handleDrawerClose}
                      size="large"
                    >
                      Cancel
                    </Button>
                    {selectReg.status === '1' ? (
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={selectChildren.length === 0}
                      >
                        Re-assign
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={
                          phoneList[0]?.memberNumber !== selectReg.memberNo ||
                          phoneList.length === 0
                        }
                      >
                        Repass
                      </Button>
                    )}
                  </Space>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {/* cSpell:disable */}
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={handleDeleteAllClick}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" danger size="large">
                      Delete all
                    </Button>
                  </Popconfirm>
                  {/* cSpell:enable */}
                </Col>
              </Row>
            </Form>
            {callingHistory.length > 0 && (
              <>
                <p style={{ margin: '30px 0 5px' }}>History:</p>
                <Table
                  rowKey="startTime"
                  columns={repassHistoryColumns}
                  dataSource={callingHistory}
                  pagination={false}
                />
              </>
            )}
          </>
        )}
        <MemberChild
          showChildDrawer={showNewChildDrawer}
          setShowChildDrawer={setShowNewChildDrawer}
          type="Add"
          fetchRegList={fetchRegList}
          selectReg={selectReg}
          selectRegTime={selectRegTime}
          songList={songList}
          selectRegAllChildren={selectRegAllChildren}
        />
        {selectChild && (
          <MemberChild
            showChildDrawer={showUpdateChildDrawer}
            setShowChildDrawer={setShowUpdateChildDrawer}
            type="Update"
            selectChild={selectChild}
            setSelectChild={setSelectChild}
            fetchRegList={fetchRegList}
            selectReg={selectReg}
            selectRegTime={selectRegTime}
          />
        )}
      </Drawer>
    </>
  );
};

export default Assign;
