/* cSpell:disable*/
import { useState, useEffect, useRef } from 'react';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

import './TotalList.css';
import Assign from '../assign/Assign';
import Spinner from '../spinner/Spinner';
/* cSpell:enable*/

const statusFilters = [
  { text: '完成', value: '1' },
  { text: 'No answer', value: '3' },
  { text: 'Other', value: '6' },
  { text: 'Call later', value: '8' },
  { text: '指定主管', value: '9' },
];

const TotalList = ({ totalList, songList }) => {
  const [showAssign, setShowAssign] = useState(false);
  const [selectReg, setSelectReg] = useState(null);
  const [crewFilters, setCrewFilters] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectRegTime, setSelectRegTime] = useState(null);
  const [selectRegAllChildren, setSelectRegAllChildren] = useState([]);

  const renderStatus = (statusCode) => {
    if (statusCode === '1') return '完成';
    if (statusCode === '3') return 'No answer';
    if (statusCode === '6') return 'Other';
    if (statusCode === '8') return 'Call later';
    if (statusCode === '9') return '指定主管';
    return statusCode;
  };

  const fetchRegList = async (memNo, regTime) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/${memNo}/${regTime}/reg-list`);
      if (data) {
        setSelectReg(data);
        setIsLoading(false);
        setShowAssign(true);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const fetchAllChildren = async (memNo) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/${memNo}/child-list`);
      if (data.length > 0) {
        setSelectRegAllChildren(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAssignClick = (record) => {
    setSelectRegTime(record.regTime);
    fetchRegList(record.memberNo, record.regTime);
    fetchAllChildren(record.memberNo);
  };

  let searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search member number"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            searchInput && searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '',
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleAssignClick(record)}
          >
            Assign
          </Button>
        );
      },
      width: 80,
    },
    {
      title: 'Member',
      dataIndex: 'memberNo',
      render: (text, record) => {
        const isNew = record.isNewMember;
        const newLabel = isNew ? '(NEW)' : null;
        if (isNew) {
          return (
            <p>
              {text} <span style={{ color: 'red' }}>{newLabel}</span>
            </p>
          );
        }
        return text;
      },
      width: 90,
      ...getColumnSearchProps('memberNo'),
    },
    {
      title: 'Kid',
      dataIndex: 'regKids',
      render: (text, record) => `${text} / ${record.familyKids}`,
      width: 70,
    },
    {
      title: 'Crew',
      dataIndex: 'crewName',
      filters: crewFilters,
      // onFilter: (value, record) => record.crewName.indexOf(value) === 0,
      onFilter: (value, record) => {
        const regExpStr = String.raw`\b${value}\b`;
        const filterRegExp = new RegExp(`${regExpStr}`);
        return filterRegExp.test(record.crewName);
      },
      width: 100,
    },
    {
      title: 'Register',
      dataIndex: 'receiveTime',
      width: 90,
    },
    {
      title: 'Reserve',
      dataIndex: 'reserveTime',
      width: 90,
    },
    {
      title: 'Realtime',
      dataIndex: 'realTimeReg',
      width: 90,
    },
    {
      title: 'Callback',
      dataIndex: 'callback',
      width: 90,
    },
    {
      title: 'Type',
      render: (text, record) =>
        record.lessons.map((lesson, i) => (
          <p key={i}>{lesson.replace('Lessons', '')}</p>
        )),
      width: 144,
    },
    {
      title: 'Repass',
      dataIndex: 'repass',
      render: (text) => (text === 0 ? '' : text),
      width: 80,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Assign',
      dataIndex: 'assignTime',
      width: 90,
    },
    {
      title: 'Start',
      dataIndex: 'startTime',
      width: 90,
    },
    {
      title: 'End',
      dataIndex: 'endTime',
      width: 90,
    },
    {
      title: 'Waiting',
      dataIndex: 'waitingTime',
      width: 90,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => renderStatus(text),
      filters: statusFilters,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      width: 120,
    },
  ];

  useEffect(() => {
    if (totalList.length > 0) {
      const allCrews = totalList.map((item) => item.crewName);
      const uniqueCrews = [...new Set(allCrews)].map((crew) => ({
        text: crew,
        value: crew,
      }));
      setCrewFilters(uniqueCrews);
    }
  }, [totalList]);

  return (
    <>
      {isLoading && <Spinner text="Loading..." />}
      <div className="total-list-container">
        <Table
          rowKey="seq"
          columns={columns}
          dataSource={totalList}
          pagination={false}
          scroll={{ y: 546 }}
        />
      </div>
      {selectReg && (
        <Assign
          showDrawer={showAssign}
          setShowDrawer={setShowAssign}
          selectReg={selectReg}
          setSelectReg={setSelectReg}
          selectRegTime={selectRegTime}
          setSelectRegTime={setSelectRegTime}
          fetchRegList={fetchRegList}
          songList={songList}
          selectRegAllChildren={selectRegAllChildren}
        />
      )}
    </>
  );
};

export default TotalList;
