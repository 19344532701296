// cSpell:disable
import { useState, useEffect, useCallback } from 'react';
import { Button, Drawer, Form, Space, Select, message } from 'antd';
import axios from 'axios';

import Spinner from '../spinner/Spinner';
// cSpell:enable

const MemberChild = ({
  showChildDrawer,
  setShowChildDrawer,
  type,
  selectChild,
  setSelectChild,
  fetchRegList,
  selectReg,
  selectRegTime,
  selectRegAllChildren,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [selectSongNo1, setSelectSongNo1] = useState(null);
  const [showSongNo2, setShowSongNo2] = useState(false);
  const [selectChildSongList, setSelectChildSongList] = useState([]);
  const [selectNewChildSeq, setSelectNewChildSeq] = useState(null);
  const [form] = Form.useForm();

  const getLessonType = async (songNo) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/te/song/${songNo}`);
      if (data.teCategory.startsWith('Song')) {
        setShowSongNo2(true);
        setIsLoading(false);
      } else {
        setShowSongNo2(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const getSongListByChild = useCallback(
    async (seq) => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `/te/${selectReg.memberNo}/${seq}/song-list`
        );
        setSelectChildSongList(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        setIsLoading(false);
      }
    },
    [selectReg]
  );

  const handleSongNo1Change = (value) => {
    // setSelectSongNo1(value);
    getLessonType(value);
  };

  const handleCreateClose = () => {
    setShowChildDrawer(false);
    setSelectChildSongList([]);
    setSelectNewChildSeq(null);
    setShowSongNo2(false);
  };

  const handleUpdateClose = () => {
    setSelectChild(null);
    setSelectChildSongList([]);
    setShowChildDrawer(false);
  };

  const handleCreateFinish = async (values) => {
    setIsLoading(true);
    const newChild = {
      ...values,
      regTime: selectRegTime,
      memberNo: selectReg.memberNo,
    };
    try {
      const { data } = await axios.post('/te/child', newChild);
      if (data) {
        message.success('新增成功', 5);
        fetchRegList(selectReg.memberNo, selectRegTime);
        form.resetFields();
        setIsLoading(false);
        setSelectChildSongList([]);
        setShowChildDrawer(false);
      } else {
        message.error('新增失敗, 請再試一次', 5);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleUpdateFinish = async (values) => {
    setIsLoading(true);
    const updatedChild = {
      seq: selectChild.seq,
      songNo1: values.songNo1,
      songNo2: values.songNo2,
    };
    try {
      const { data } = await axios.patch(
        '/te/registration-detail-song',
        updatedChild
      );
      if (data) {
        message.success('更新成功', 5);
        fetchRegList(selectReg.memberNo, selectRegTime);
        form.resetFields();
        setIsLoading(false);
        setSelectChild(null);
        setSelectChildSongList([]);
        setShowChildDrawer(false);
      } else {
        message.error('更新失敗, 請再試一次', 5);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (type === 'Update') {
      getLessonType(selectChild.songNo1);
    }
  }, [type, selectChild]);

  useEffect(() => {
    if (selectChild) {
      getSongListByChild(selectChild.childSeq);
    }
  }, [selectChild, getSongListByChild]);

  useEffect(() => {
    if (selectNewChildSeq) {
      getSongListByChild(selectNewChildSeq);
    }
  }, [selectNewChildSeq, getSongListByChild]);

  // useEffect(() => {
  //   if (selectChild) {
  //     setSelectSongNo1(selectChild.songNo1);
  //   }
  // }, [selectChild]);

  // useEffect(() => {
  //   if (selectSongNo1) {
  //     getLessonType(selectSongNo1);
  //   }
  // }, [selectSongNo1]);

  return (
    <>
      {isLoading && <Spinner text="Loading..." />}
      <Drawer
        title={`${type} Kid`}
        visible={showChildDrawer}
        onClose={type === 'Update' ? handleUpdateClose : handleCreateClose}
        width={500}
      >
        <Form
          form={form}
          name="reg-form"
          onFinish={type === 'Update' ? handleUpdateFinish : handleCreateFinish}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{
            songNo1: selectChild ? selectChild.songNo1 : '',
            songNo2: selectChild ? selectChild.songNo2 : '',
          }}
        >
          <Form.Item
            label="Kid"
            name={type !== 'Update' && 'childSeqNo'}
            rules={[{ required: type === 'Add' ? true : false }]}
          >
            {type === 'Update' ? (
              selectChild.childChineseName
            ) : (
              <Select onChange={(val) => setSelectNewChildSeq(val)}>
                {selectRegAllChildren.map((child) => (
                  <Select.Option
                    value={child.seqNo}
                    key={child.seqNo}
                    disabled={selectReg.regDetail.find(
                      (item) => item.childSeq === child.seqNo
                    )}
                  >
                    {child.childChineseName} - {child.childTeName}
                    {child.regCountCurrentWeekday !== 0 &&
                      `(Already register ${child.registeredTimes} times this week)`}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Lesson 1"
            name="songNo1"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={handleSongNo1Change}
            >
              {selectChildSongList.map((song) => (
                <Select.Option
                  value={song.songNo}
                  key={song.songNo}
                  style={{
                    color:
                      ((song.teCategory === 'Right Light Lessons' &&
                        !song.allowRightLightLesson) ||
                        (song.teCategory === 'Free Conversation' &&
                          !song.allowFreeConversation)) &&
                      'red',
                  }}
                >
                  {`${song.songNo} - ${song.songName}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {showSongNo2 && (
            <Form.Item label="Lesson 2" name="songNo2">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {selectChildSongList
                  .filter((song) => song.teCategory === 'Song Lessons')
                  .map((song) => (
                    <Select.Option
                      value={song.songNo}
                      key={song.songNo}
                      style={{
                        color:
                          ((song.teCategory === 'Right Light Lessons' &&
                            !song.allowRightLightLesson) ||
                            (song.teCategory === 'Free Conversation' &&
                              !song.allowFreeConversation)) &&
                          'red',
                      }}
                    >
                      {`${song.songNo} - ${song.songName}`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Space>
              <Button size="large" onClick={() => setShowChildDrawer(false)}>
                Cancel
              </Button>
              <Button type="primary" size="large" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default MemberChild;
