// cSpell:disable
import { Tooltip, Typography } from 'antd';

import './SummaryItem.css';
// cSpell:enable

const SummaryItem = ({
  title,
  value,
  assignedNotCallCrew,
  reserveNotAssign,
  realTimeNotAssign,
  reverseNotCall,
}) => {
  if (title === '已派未撥通數') {
    return value !== '0' && assignedNotCallCrew.length !== 0 ? (
      <div className="summary-item warning">
        <Tooltip title={title}>
          <Typography.Title level={5}>TTL. DIST. Awaits Calls</Typography.Title>
        </Tooltip>
        <Tooltip
          title={() =>
            assignedNotCallCrew.map((item, i) => (
              <p style={{ margin: 0 }} key={i}>
                {item}
              </p>
            ))
          }
          placement="bottom"
          color="lightCoral"
          overlayClassName="tool-tip"
        >
          <Typography.Paragraph>{value}</Typography.Paragraph>
        </Tooltip>
      </div>
    ) : (
      <div className="summary-item">
        <Tooltip title={title}>
          <Typography.Title level={5}>TTL. DIST. Awaits Calls</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title.includes('預約未派')) {
    return (
      <div
        className={
          reserveNotAssign === 'Y' ? 'summary-item warning' : 'summary-item'
        }
      >
        <Tooltip title="預約未派">
          <Typography.Title level={5}>{`RMNG. RES. Calls ${
            title.split(' ')[1]
          }`}</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === '即時未派') {
    return (
      <div
        className={
          realTimeNotAssign === 'Y' ? 'summary-item warning' : 'summary-item'
        }
      >
        <Tooltip title={title}>
          <Typography.Title level={5}>RMNG. Real-Time Calls</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === '預約回撥超時通數') {
    return (
      <div
        className={
          reverseNotCall === 'Y' ? 'summary-item warning' : 'summary-item'
        }
      >
        <Tooltip title={title}>
          <Typography.Title level={5}>Delayed RES. Calls</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === '總等待通數') {
    return (
      <div className="summary-item">
        <Tooltip title={title}>
          <Typography.Title level={5}>TTL. Waiting Calls</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === '總等待小孩數') {
    return (
      <div className="summary-item">
        <Tooltip title={title}>
          <Typography.Title level={5}>TTL. Waiting Children</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === '休息 Crew') {
    return (
      <div className="summary-item">
        <Typography.Title level={5}>Unavailable Crew</Typography.Title>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === '總通數') {
    return (
      <div className="summary-item">
        <Tooltip title={title}>
          <Typography.Title level={5}>TTL. CMPLT. Calls</Typography.Title>
        </Tooltip>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
  if (title === 'Available Crew') {
    return (
      <div className="summary-item">
        <Typography.Title level={5}>Available Crew</Typography.Title>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </div>
    );
  }
};

export default SummaryItem;
