/* eslint-disable react-hooks/exhaustive-deps */
// cSpell:disable
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Layout,
  Button,
  Typography,
  Switch,
  Drawer,
  Space,
  Tag,
  Form,
  Input,
} from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import ConfigContext from "../../ConfigContext";
import { LogoutOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

import "./Header.css";
// cSpell:enable

const Header = ({ currentUser, instance, setCurrentUser }) => {
  const { apiUrl } = useContext(ConfigContext);
  const [isRemindMsgOn, setIsRemindMsgOn] = useState(false);
  const [remindMsg, setRemindMsg] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [currentMsg, setCurrentMsg] = useState("");
  const [form] = Form.useForm();

  const getReminderMsgStatus = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/te/real-time/reminder-message`
      );
      setCurrentStatus(data.isReminderMessageEnabled);
      setIsRemindMsgOn(data.isReminderMessageEnabled);
      setCurrentMsg(data.reminderMessage);
      setRemindMsg(data.reminderMessage);
    } catch (error) {
      console.log(error.message);
    }
  };

  const logout = async (crewNo) => {
    try {
      await axios.post(
        `${apiUrl}/te/supervisor/logout`,
        { crewNo },
        {
          headers: { "User-Code": crewNo },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogOut = async () => {
    await logout(currentUser.code);
    setCurrentUser(null);
    instance.logoutPopup();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedData = {
        isReminderMessageEnabled: isRemindMsgOn,
        reminderMessage: isRemindMsgOn ? remindMsg : "",
      };
      await axios.patch(`${apiUrl}/te/real-time/reminder-message`, updatedData);
      await getReminderMsgStatus();
      setShowDrawer(false);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReminderMsgStatus();
  }, []);

  return (
    <>
      <Layout.Header className="header">
        <div className="logo-container">
          <Typography.Title level={2}>
            <FontAwesomeIcon icon={faPhoneVolume} />
            TE Supervisor
          </Typography.Title>
        </div>
        <div className="sign-out-container">
          <div
            style={{
              backgroundColor: "#FFFFFF",
              marginRight: 24,
              display: "flex",
              alignItems: "center",
              height: 40,
              padding: "0 8px",
              borderRadius: 4,
            }}
          >
            <p style={{ color: "#000000", marginRight: 8 }}>
              Real-time registration reminder message status:{" "}
              <span>
                {currentStatus ? (
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    ON
                  </Tag>
                ) : (
                  <Tag icon={<CloseCircleOutlined />} color="error">
                    OFF
                  </Tag>
                )}
              </span>
            </p>
            <Button
              type="primary"
              size="small"
              onClick={() => setShowDrawer(true)}
            >
              Change
            </Button>
          </div>
          <p>Hello, {`${currentUser.englishName} (${currentUser.code})`}</p>
          <Button
            onClick={handleLogOut}
            className="sign-out-btn"
            icon={<LogoutOutlined />}
            size="small"
          >
            Log out
          </Button>
        </div>
      </Layout.Header>
      <Drawer
        title="Real-time registration reminder message"
        placement="right"
        width={550}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        extra={
          <Space>
            <Button onClick={() => setShowDrawer(false)}>Cancel</Button>
            <Button type="primary" onClick={handleSave} loading={isLoading}>
              SAVE
            </Button>
          </Space>
        }
        destroyOnClose
      >
        <Form
          form={form}
          name="reminder message"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item label="Status" rules={[{ required: true }]}>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              defaultChecked={currentStatus}
              onClick={(checked) => {
                setIsRemindMsgOn(checked);
                if (checked) {
                  setRemindMsg(
                    "目前電話美語即時預約數量較多，預計等候時間較長，請您見諒。"
                  );
                } else {
                  setRemindMsg("");
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Message"
            rules={[{ required: isRemindMsgOn ? true : false }]}
          >
            <Input.TextArea
              rows={5}
              defaultValue={currentMsg}
              value={remindMsg}
              onChange={(e) => setRemindMsg(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Header;
